import { IToastConfig } from './toast-notification.interface';
import { InjectionToken } from '@angular/core';

export const defaultToastConfig: IToastConfig = {
	position: {
		top: 20,
		right: 20
	},
	animation: {
		fadeOut: 2500,
		fadeIn: 300
	}
};

export const TOAST_CONFIG_TOKEN = new InjectionToken('toast-config');
