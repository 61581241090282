import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { MsUserHttpClient } from '../../common/httpClinet/msUser/msUser.client';
import { MsFileHttpClient } from '../../common/httpClinet/msFile/msFile.client';

@Injectable({
	providedIn: 'root'
})
export class UserImplRepository {
	constructor(private http: MsUserHttpClient, private msFileHttpClient: MsFileHttpClient) {}

	getAllPostByUser(username: string, status: string): Observable<any> {
		return this.http.get(`/user/${username}/post`, {
			params: {
				status
			}
		});
	}

	getProfileDetail(): Observable<any> {
		return this.http.get('/user/detail/me');
	}

	updateProfile(payload: object): Observable<any> {
		return this.http.patch('/user/update', payload);
	}

	followUser(params: object): Observable<any> {
		return this.http.post('/follow', params);
	}

	unfollowUser(username: string): Observable<any> {
		return this.http.delete(`/unfollow/${username}`);
	}

	getFollowingStatus(username: string): Observable<any> {
		return this.http.get(`/following/${username}/status`);
	}

	getFollowing(username: string, page: number): Observable<any> {
		return this.http.get(`/following/${username}`, {
			params: {
				page
			}
		});
	}

	getFollowers(username: string, page: number): Observable<any> {
		return this.http.get(`/followers/${username}`, {
			params: {
				page
			}
		});
	}

	uploadProfilePicture(formData: FormData): Observable<any> {
		return this.msFileHttpClient.post('/public/upload/profile-picture', formData);
	}

	getFeaturedUser(): Observable<any> {
		return this.http.get('/users/featured');
	}

	getTopContributor(limit: number): Observable<any> {
		return this.http.get(`/users/top-contributors?limit=${limit}`);
	}
}
