import { Injectable } from '@angular/core';
import { MsUserHttpClient } from '../../common/httpClinet/msUser/msUser.client';
import { Observable } from 'rxjs';
import { ICreateCommentPayload } from '@data/interface/comments.interface';

@Injectable({ providedIn: 'root' })
export class CommentsImplRepository {
	constructor(private http: MsUserHttpClient) {}

	createComment(chapterId: string, payload: ICreateCommentPayload): Observable<any> {
		return this.http.post(`/chapter/comment/${chapterId}`, payload);
	}

	getComments(chapterId: string, page: number): Observable<any> {
		return this.http.get(`/chapter/comment/${chapterId}?page=${page}`);
	}

	likeComment(commentId: string): Observable<any> {
		return this.http.post(`/chapter/comment/like/${commentId}`, {});
	}

	unlikeComment(commentId: string): Observable<any> {
		return this.http.delete(`/chapter/comment/like/${commentId}`);
	}
}
