<div class="w-full flex flex-col py-8 px-5">
	<div class="w-full flex flex-row justify-center items-center">
		<div
			class="flex flex-col bg-yellow-50 rounded-full h-16 w-16 text-yellow-600 justify-center items-center text-center hidden">
			<mat-icon
				class="flex text-yellow-600 justify-center items-center w-14 h-14"
				[svgIcon]="'heroicons_outline:exclamation-triangle'"></mat-icon>
		</div>
	</div>
	<h3
		class="w-full flex flex-row text-center justify-center items-center text-lg font-medium leading-6 text-gray-900 mt-2 mb-0">
		{{ data.title || 'Warning' }}
	</h3>
	<div class="mt-1">
		<p class="text-sm text-gray-500 justify-center items-center text-center px-5">
			{{ data.message }}
		</p>
	</div>
	<div class="w-full flex flex-row justify-center items-center">
		<div
			(click)="dialogRef.close()"
			class="waca-bg-primary mt-1 text-white cursor-pointer rounded-full w-4/12 h-10 flex justify-center items-center">
			Ok
		</div>
	</div>
</div>
