import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { MsFileHttpClient } from '../../common/httpClinet/msFile/msFile.client';

@Injectable({
	providedIn: 'root'
})
export class ServiceImplRepository {
	constructor(private http: MsFileHttpClient) {}

	uploadCover(params: any): Observable<any> {
		return this.http.post(`/public/upload/cover`, params);
	}
}
