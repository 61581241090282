import { CollectionCategoryEnum } from '@data/enum/ebook.bookmark.enum';
import { Observable } from 'rxjs';

export abstract class EbookBookmarkRepository {
	abstract getEbookStatus(ebookId: string): Observable<any>;
	abstract getListEbookBookmarkSelf(): Observable<any>;
	abstract addEbookBookmark(payload: object): Observable<any>;
	abstract removeEbookBookmark(payload: object): Observable<any>;
	abstract updateEbookBookmark(action: string, ebookId: string): Observable<any>;
	abstract getCollection(category: CollectionCategoryEnum): Observable<any>;
}
