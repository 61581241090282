export class ProfileModel {
	id: string;
	fullName: string;
	username: string;
	email: string;
	gender: string;
	photoURL: string;
	dateOfBirth: string;
	registrationStatus: string;
	isVerified: boolean;
	socialId: string;
	biography: string;
	role: string;
	totalLikes: number;
	totalPostView: number;
	totalFollowers: number;
	totalFollowing: number;
	badges: any[];

	constructor() {
		this.id = '';
		this.fullName = '';
		this.username = '';
		this.email = '';
		this.gender = '';
		this.photoURL = '';
		this.dateOfBirth = '';
		this.registrationStatus = '';
		this.isVerified = false;
		this.socialId = '';
		this.biography = '';
		this.role = 'WRITER';
		this.totalLikes = 0;
		this.totalPostView = 0;
		this.totalFollowers = 0;
		this.totalFollowing = 0;
		this.badges = [];
	}
}
