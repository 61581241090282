import { Observable } from 'rxjs';

export abstract class ChapterRepository {
	abstract createChapter(params: any): Observable<any>;

	abstract getListPublishedChapterByPostId(postId: string): Observable<any>;

	abstract getAllChapterByPostId(postId: string): Observable<any>;

	abstract deleteChapter(params: any): Observable<any>;

	abstract getDetailChapter(chapterId: string): Observable<any>;

	abstract getDetailChapterForAuthor(chapterId: string): Observable<any>;

	abstract editChapter(params: any): Observable<any>;

	abstract savePendingChanges(
		postId: string,
		chapterId: string,
		payload: object
	): Observable<any>;

	abstract getLikeStatus(chapterId: string): Observable<any>;

	abstract getFirstChapter(postId: string): Observable<any>;

	abstract addLike(payload: object): Observable<any>;

	abstract removeLike(payload: object): Observable<any>;
}
