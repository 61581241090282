import { Injectable } from '@angular/core';
import { DialogNotificationComponent } from './dialog-notification.component';
import { IDialogNotificationConfig } from './dialog-notification.interface';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

@Injectable()
export class DialogNotificationService {
	constructor(private dialog: MatDialog) {}

	dialogRef: MatDialogRef<DialogNotificationComponent> | null = null;

	public open(options: IDialogNotificationConfig) {
		return this.dialog.open(DialogNotificationComponent, {
			data: {
				title: options.title,
				message: options.message,
				type: options.type ?? 'warning'
			}
		});
	}

	public confirmed(): Observable<any> {
		if (!this.dialogRef) {
			return new Observable().pipe(map(() => false));
		}
		return this.dialogRef.afterClosed().pipe(
			take(1),
			map((res) => {
				return res;
			})
		);
	}
}
