import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialogPaidConfirmationComponent } from '@components/dialog-paid-confirmation/dialog-paid-confirmation.component';
import { UserWacaKoinModule } from '@components/user-waca-koin/user-waca-koin.module';
import { MatDialogModule } from '@angular/material/dialog';
import { ButtonModule } from '@components/button/button.module';
import { DirectivesModule } from '../../../common/directive/count-up/directives.module';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@NgModule({
	declarations: [DialogPaidConfirmationComponent],
	imports: [
		CommonModule,
		UserWacaKoinModule,
		MatDialogModule,
		ButtonModule,
		DirectivesModule,
		MatProgressSpinnerModule
	]
})
export class DialogPaidConfirmationModule {
	public static forRoot(): ModuleWithProviders<any> {
		return {
			ngModule: DialogPaidConfirmationModule
		};
	}
}
