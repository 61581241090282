import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment, environment as devEnvironment } from '../../../../environments/environment';
import { environment as prodEnvironment } from '../../../../environments/environment.prod';
import { Observable } from 'rxjs';

export interface IRequestOptions {
	headers?: HttpHeaders;
	observe?: 'body';
	params?:
		| HttpParams
		| { [param: string]: string | number | boolean | ReadonlyArray<string | number | boolean> };
	reportProgress?: boolean;
	responseType?: 'json';
	withCredentials?: boolean;
	body?: any;
}

export function msStoreHttpClientCreator(http: HttpClient) {
	return new MsStoreHttpClient(http);
}

@Injectable()
export class MsStoreHttpClient {
	baseUrl = !environment.production
		? devEnvironment.msStoreBaseUrl
		: prodEnvironment.msStoreBaseUrl;

	// Extending the HttpClient through the Angular DI.
	public constructor(public http: HttpClient) {
		// If you don't want to use the extended versions in some cases you can access the public property and use the original one.
		// for ex. this.httpClient.http.get(...)
	}

	/**
	 * GET request
	 * @param {string} endPoint it doesn't need / in front of the end point
	 * @param {IRequestOptions} options options of the request like headers, body, etc.
	 * @returns {Observable<T>}
	 */
	public get<T>(endPoint: string, options?: IRequestOptions): Observable<T> {
		return this.http.get<T>(this.baseUrl + endPoint, options);
	}

	/**
	 * POST request
	 * @param {string} endPoint end point of the api
	 * @param {Object} params body of the request.
	 * @param {IRequestOptions} options options of the request like headers, body, etc.
	 * @returns {Observable<T>}
	 */
	public post<T>(endPoint: string, params: object, options?: IRequestOptions): Observable<T> {
		return this.http.post<T>(this.baseUrl + endPoint, params, options);
	}

	/**
	 * PATCH request
	 * @param {string} endPoint end point of the api
	 * @param {Object} params body of the request.
	 * @param {IRequestOptions} options options of the request like headers, body, etc.
	 * @returns {Observable<T>}
	 */
	public patch<T>(endPoint: string, params: object, options?: IRequestOptions): Observable<T> {
		return this.http.patch<T>(this.baseUrl + endPoint, params, options);
	}

	/**
	 * PUT request
	 * @param {string} endPoint end point of the api
	 * @param {Object} params body of the request.
	 * @param {IRequestOptions} options options of the request like headers, body, etc.
	 * @returns {Observable<T>}
	 */
	public put<T>(endPoint: string, params: object, options?: IRequestOptions): Observable<T> {
		return this.http.put<T>(this.baseUrl + endPoint, params, options);
	}

	/**
	 * DELETE request
	 * @param {string} endPoint end point of the api
	 * @param {IRequestOptions} options options of the request like headers, body, etc.
	 * @returns {Observable<T>}
	 */
	public delete<T>(endPoint: string, options?: IRequestOptions): Observable<T> {
		return this.http.delete<T>(this.baseUrl + endPoint, options);
	}
}
