export class LocalstorageDatasource {
	private readonly STORAGE_KEY = 'seenTutorials';
	private readonly DEFAULT_DATA = '{ "data": [] }';

	getSeenTutorial(): string[] {
		const data = JSON.parse(localStorage.getItem(this.STORAGE_KEY) || this.DEFAULT_DATA);
		return data.data;
	}

	setSeenTutorial(newKey: string): void {
		const rawData = localStorage.getItem(this.STORAGE_KEY);
		let data: { data: string[] };

		if (rawData) {
			data = JSON.parse(rawData);
			data.data.push(newKey);
		} else {
			data = { data: [newKey] };
		}

		localStorage.setItem(this.STORAGE_KEY, JSON.stringify(data));
	}

	clear(): void {
		localStorage.removeItem('_at');
		localStorage.removeItem('_rt');
		localStorage.removeItem('_u');
	}
}
