import { Injectable } from '@angular/core';
import { ProfileModel } from '@data/models/profile.model';
import { Subject } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class UserService {
	private userDetailSubject = new Subject<ProfileModel | null>();
	userDetail$ = this.userDetailSubject.asObservable();

	constructor() {}

	emitEvent(userDetail: ProfileModel) {
		this.userDetailSubject.next(userDetail);
	}

	clearEvent() {
		this.userDetailSubject.next(null);
	}
}
