import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { MsUserHttpClient } from '../../common/httpClinet/msUser/msUser.client';

@Injectable({
	providedIn: 'root'
})
export class ReadingHistoryImplRepository {
	constructor(private http: MsUserHttpClient) {}

	getLatestChapterReadByPostId(postId: string): Observable<any> {
		return this.http.get(`/posts/${postId}/latest-chapter`);
	}

	logChapterView(payload: object): Observable<any> {
		return this.http.post(`/a/c/cv`, payload);
	}

	logChapterViewForVisitor(payload: object): Observable<any> {
		return this.http.post(`/a/c/cvv`, payload);
	}

	logShare(postId: string): Observable<any> {
		return this.http.post(`/a/c/sh/p/${postId}`, { p: postId });
	}

	getReadingHistory(username: string): Observable<any> {
		return this.http.get(`/reading-history/${username}`);
	}
}
