import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { MsStoreHttpClient } from '../../common/httpClinet/msStore/msStore.client';
import { IRequestRandomList, IRequestGenre } from '@data/interface/store.interface';

@Injectable({
	providedIn: 'root'
})
export class StoreImplRepository {
	constructor(private http: MsStoreHttpClient) {}

	getGenres(params: IRequestGenre): Observable<any> {
		return this.http.get('/conf/genre', {
			params: {
				...params
			}
		});
	}

	getCategories(): Observable<any> {
		return this.http.get('/conf/category');
	}

	getRandomList(params: IRequestRandomList): Observable<any> {
		return this.http.get('/ebook/reader/random', {
			params: {
				...params
			}
		});
	}

	getDetailEbook(id: string): Observable<any> {
		return this.http.get(`/ebook/reader/${id}`);
	}

	getSearch(params: IRequestRandomList): Observable<any> {
		return this.http.get(`/ebook/reader/list/search`, {
			params: {
				...params
			}
		});
	}
}
