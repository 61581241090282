import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { MsUserHttpClient } from '../../common/httpClinet/msUser/msUser.client';

@Injectable({
	providedIn: 'root'
})
export class ConfigurationImplRepository {
	constructor(private http: MsUserHttpClient) {}

	getCategories(): Observable<any> {
		return this.http.get('/config/artwork/category');
	}

	getGenres(): Observable<any> {
		return this.http.get('/config/artwork/genre');
	}

	getGenresNonFiction(): Observable<any> {
		return this.http.get('/config/artwork/genre/non-fiction');
	}

	getActiveGenreByCategory(code: string): Observable<any> {
		return this.http.get(`/config/summary/genre/${code}`);
	}

	getCopyright(): Observable<any> {
		return this.http.get('/config/artwork/copyright');
	}

	getAgeRestriction(): Observable<any> {
		return this.http.get('/config/age-restriction');
	}

	setDraftConfiguration(params: any): Observable<any> {
		return params;
	}

	getTopGenre(limit: number): Observable<any> {
		return this.http.get(`/config/summary/top-genre?limit=${limit}`);
	}
}
