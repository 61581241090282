import { Observable } from 'rxjs';
import { MsUserHttpClient } from '../../common/httpClinet/msUser/msUser.client';
import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root'
})
export class NotificationBannerImplRepository {
	constructor(private http: MsUserHttpClient) {}

	getNotificationBanner(): Observable<any> {
		return this.http.get('/notification/banner');
	}

	deleteNotificationBanner(id: string): Observable<any> {
		return this.http.delete(`/notification/banner/${id}`);
	}
}
