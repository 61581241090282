import { MsUserHttpClient } from '../../common/httpClinet/msUser/msUser.client';
import { Observable } from 'rxjs';
import { IUserPreference } from '@data/interface/userPreference.interface';
import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root'
})
export class UserPreferenceImplRepository {
	constructor(private http: MsUserHttpClient) {}

	saveUserPreference(payload: IUserPreference): Observable<any> {
		return this.http.post('/user/preference', payload);
	}

	getPreferenceByUser(): Observable<any> {
		return this.http.get(`/user/preference`);
	}
}
