import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { MsUserHttpClient } from '../../common/httpClinet/msUser/msUser.client';

@Injectable({
	providedIn: 'root'
})
export class ReviewImplRepository {
	constructor(private http: MsUserHttpClient) {}

	getAllReviewByPostId(postId: string): Observable<any> {
		return this.http.get(`/post/${postId}/review`);
	}

	getPaginateReviewByPostId(postId: string, query: object): Observable<any> {
		return this.http.get(`/post/${postId}/reviews`, {
			params: {
				...query
			}
		});
	}

	getMyReviewByPostId(postId: string): Observable<any> {
		return this.http.get(`/post/${postId}/review/me`);
	}

	submitReview(payload: object, postId: string): Observable<any> {
		return this.http.patch(`/post/${postId}/review`, payload);
	}
}
