<!-- <button class="btn-read w-100">Baca Sekarang</button> -->
<button
	[ngClass]="[
		isDisabled ? 'btn-disable' : isOutline ? 'btn-outline' : 'btn-fill',
		customClass,
		'btn'
	]">
	<div *ngIf="icon">
		<img [src]="icon" class="max-w-full mr-3" alt="wacaku - monetisaku" />
	</div>
	<div *ngIf="isLoading">
		<span class="w-loader -mt-1 mb-3 block" [ngClass]="isDisabled ? '' : 'text-white'"></span>
	</div>
	<ng-container *ngIf="!isLoading">
		{{ text }}
	</ng-container>
</button>
