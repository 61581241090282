import { MsPaymentHttpClient } from '@common/httpClinet/msPayment/msPayment.client';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { MsUserHttpClient } from '@common/httpClinet/msUser/msUser.client';
import { MsAnalyticsHttpClient } from '@common/httpClinet/msAnalytics/msAnalytics.client';
import { MsStoreHttpClient } from '@common/httpClinet/msStore/msStore.client';

@Injectable({
	providedIn: 'root'
})
export class PaymentImplRepository {
	constructor(
		private httpClient: MsPaymentHttpClient,
		private msUserHttpClient: MsUserHttpClient,
		private msAnalyticsHttpClient: MsAnalyticsHttpClient,
		private msStoreHttpStore: MsStoreHttpClient
	) {}

	getListTopUpProduct(): Observable<any> {
		const url = `/payment/topup/list?platform=Web`;
		return this.msUserHttpClient.get(url);
	}

	getWriterRoyalty(): Observable<any> {
		return this.msAnalyticsHttpClient.get(`/royalty/summary/writer`);
	}

	getBalance(userId: string): Observable<any> {
		const url = `/payment/user/${userId}/balance`;
		return this.msUserHttpClient.get(url);
	}

	getOrderDetail(orderId: string): Observable<any> {
		const url = `/api/v1/midtrans/detail?transaction_number=${orderId}`;

		return this.httpClient.get(url);
	}

	acquireTopUpGateway(payload: object): Observable<any> {
		const url = `/payment/topup/inquiry`;
		return this.msUserHttpClient.post(url, payload);
	}

	getHistoryTopup(userId: string, limit: string, page: string) {
		const param = {
			user_id: userId,
			limit,
			page
		};

		const searchParams = new URLSearchParams(param);
		const url = `/payment/history/top-up?${searchParams.toString()}`;
		return this.msUserHttpClient.get(url);
	}

	getCoinUsageHistory(userId: string, limit: string, page: string) {
		const param = {
			user_id: userId,
			limit,
			page
		};

		const searchParams = new URLSearchParams(param);
		const url = `/payment/history/coin?${searchParams.toString()}`;
		return this.msUserHttpClient.get(url);
	}

	purchaseChapter(chapterId: string): Observable<any> {
		return this.msUserHttpClient.post(`/payment/chapter`, {
			chapterId
		});
	}

	getPaymentMethod(): Observable<any> {
		return this.msUserHttpClient.get(`/payment/methods`);
	}

	purchaseWacaAiPackage(packageId: string): Observable<any> {
		return this.msUserHttpClient.post(`/payment/ai/package/${packageId}`, {});
	}

	purchaseEbook(ebookId: string): Observable<any> {
		return this.msStoreHttpStore.post(`/payment/ebook/${ebookId}/buy`, {});
	}

	getHistoryPaymentEbook(): Observable<any> {
		return this.msStoreHttpStore.get(`/payment/history`);
	}

	checkOwnershipEbook(ebookId: string): Observable<any> {
		return this.msStoreHttpStore.get(`/payment/ownership/ebook/${ebookId}`);
	}
}
