import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BottomNavigationShopComponent } from './bottom-navigation-shop.component';
import { RouterLink } from '@angular/router';

@NgModule({
	declarations: [BottomNavigationShopComponent],
	exports: [BottomNavigationShopComponent],
	imports: [CommonModule, RouterLink]
})
export class BottomNavigationShopModule {}
