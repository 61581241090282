import { Observable } from 'rxjs';

export abstract class WacaAiRepository {
	abstract getAllPrompts(): Observable<any>;
	abstract getAllPackages(): Observable<any>;
	abstract getRemainingQuotaAndDuration(): Observable<any>;
	abstract generateWordsFromPrompt(payload: {
		promptId: string;
		sentence: string;
	}): Observable<any>;
}
