import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
	selector: 'app-dialog-notification',
	templateUrl: './dialog-notification.component.html',
	styleUrls: ['./dialog-notification.component.scss']
})
export class DialogNotificationComponent {
	constructor(
		public dialogRef: MatDialogRef<DialogNotificationComponent>,
		@Inject(MAT_DIALOG_DATA)
		public data: {
			title: string;
			message: string;
			type: 'success' | 'error' | 'warning' | 'info';
		}
	) {}

	public close(): void {
		this.dialogRef.close();
	}
}
