<div class="container-modal rounded-xl bg-white">
	<form [formGroup]="form" class="hidden">
		<div mat-dialog-content class="p-0 m-0">
			<div class="modal-desc hidden">
				<div class="w-full px-6 flex flex-col items-start">
					<div class="w-full flex flex-row items-center justify-center">
						<img
							class="img-logo"
							src="/assets/images/logo/wacaku-logo.svg"
							alt="logo" />
					</div>

					<!--Email-->
					<div class="mb-2 font-semibold">Email</div>
					<mat-form-field class="w-full" floatLabel="always" appearance="outline">
						<input matInput placeholder="Email" formControlName="fcEmail" />
						<mat-error *ngIf="formObject.fcEmail.hasError('required')">
							Email harus diisi
						</mat-error>
					</mat-form-field>

					<!--Password-->
					<div class="mb-2">Password</div>
					<mat-form-field class="w-full" floatLabel="always" appearance="outline">
						<input
							matInput
							type="password"
							placeholder="Password"
							formControlName="fcPassword" />
						<mat-error *ngIf="formObject.fcPassword.hasError('required')">
							Password harus diisi
						</mat-error>
					</mat-form-field>
				</div>

				<!-- Actions -->
				<div class="w-full flex flex-row items-end justify-end w-full px-6">
					<a
						class="text-md font-medium text-primary-500 hover:underline"
						[routerLink]="['/forgot-password']">
						Lupa password?
					</a>
				</div>

				<div
					*ngIf="errorMessage !== ''"
					class="w-full p-4 mb-4 text-sm text-red-800 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400">
					{{ errorMessage }}
				</div>

				<!-- Submit button -->
				<div class="w-full flex">
					<app-button
						class="w-full mx-8 mt-2.5"
						(click)="doLoginWithEmailAndPassword()"
						[isLoading]="isLoading"
						[customClass]="'w-full rounded-full'"
						[text]="'Login'"
						[isDisabled]="form.invalid || isLoading">
					</app-button>
				</div>
			</div>

			<!-- Separator -->
			<div class="flex items-center mt-5 hidden">
				<div class="flex-auto mt-px border-t"></div>
				<div class="mx-2 text-secondary">atau login dengan</div>
				<div class="flex-auto mt-px border-t"></div>
			</div>

			<!--Social Login-->
			<div class="pl-4 pr-4 mt-5">
				<!--Login with google-->
				<div class="cursor-pointer py-3 btn-login" (click)="doLoginWithGoogle()">
					<img class="img-icons mr-3" src="/assets/icons/icon-google.png" />
					Login dengan akun <b class="ml-1"> Google</b>
				</div>
			</div>

			<div
				class="w-full text-center flex items-center justify-center mt-4 font-medium text-red-700 hidden">
				<div>Belum punya akun?</div>
				<a class="ml-1 text-primary-500 hover:underline" [routerLink]="['/sign-up']">
					Buat akun baru
				</a>
			</div>
		</div>
	</form>
	<div mat-dialog-content>
		<div class="header-modal">
			<img class="img-logo" src="/assets/images/logo/wacaku-logo.svg" />
		</div>
		<div class="modal-desc">
			<div class="text-desc">Kamu bisa login ke Wacaku dengan menggunakan</div>
		</div>
		<div class="pl-4 pr-4 mt-5">
			<div
				class="cursor-pointer pt-4 pb-4 btn-login hover:bg-gray-100"
				(click)="doLoginWithGoogle()">
				<mat-progress-spinner
					*ngIf="isLoading"
					mode="indeterminate"
					diameter="30"
					class="mr-3"></mat-progress-spinner>
				<img class="img-icons mr-3" src="/assets/icons/icon-google.png" alt="google icon" />
				Login dengan akun <b class="ml-1"> Google</b>
			</div>
		</div>
		<div class="pl-4 pr-4 mt-6" style="text-align: center; font-size: 14px">
			Dengan masuk ke Wacaku berarti saya telah menyetujui
			<u>
				<strong>
					<a (click)="readTermAndCondition()"> aturan dan ketentuan </a>
				</strong>
			</u>
			yang berlaku
		</div>
	</div>
	<div class="mt-3 action" mat-dialog-actions>
		<div class="cursor-pointer" (click)="onClose()">Batal</div>
	</div>
</div>
