import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BottomNavigationComponent } from './bottom-navigation.component';
import { RouterLink } from '@angular/router';

@NgModule({
	declarations: [BottomNavigationComponent],
	exports: [BottomNavigationComponent],
	imports: [CommonModule, RouterLink]
})
export class BottomNavigationModule {}
