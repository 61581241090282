import { Injectable } from '@angular/core';
import { DialogConfirmationComponent } from './dialog-confirmation.component';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

@Injectable()
export class DialogConfirmationService {
	constructor(private dialog: MatDialog) {}

	dialogRef: MatDialogRef<DialogConfirmationComponent> | null = null;

	public open(message: string, confirmText: string = 'Ya') {
		this.dialogRef = this.dialog.open(DialogConfirmationComponent, {
			data: {
				message,
				confirmText
			}
		});
	}

	public confirmed(): Observable<any> {
		if (!this.dialogRef) {
			return new Observable().pipe(map(() => false));
		}
		return this.dialogRef.afterClosed().pipe(
			take(1),
			map((res) => {
				return res;
			})
		);
	}
}
