import { MsUserHttpClient } from '../../common/httpClinet/msUser/msUser.client';
import { MsStoreHttpClient } from '@common/httpClinet/msStore/msStore.client';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root'
})
export class EbookImplRepository {
	constructor(private http: MsUserHttpClient, private storeHttp: MsStoreHttpClient) {}

	getBestSellingEbook(query: string): Observable<any> {
		return this.http.get(`/product/ebook/list/best-seller${query}`);
	}

	getNewListingEbook(query: string): Observable<any> {
		return this.http.get(`/product/ebook/list/new-listing${query}`);
	}

	getRandomEbook(query: string): Observable<any> {
		return this.http.get(`/product/ebook/list/random${query}`);
	}

	getDetailEbook(id: string): Observable<any> {
		return this.http.get(`/product/ebook/${id}`);
	}

	getSearchEbook(keyword: string): Observable<any> {
		return this.http.get(`/product/ebook/search?keyword=${keyword}`);
	}

	getEbookByPublisher(params: object, publisherId: string): Observable<any> {
		return this.storeHttp.get(`/ebook/reader/publisher/${publisherId}/list`, {
			params: {
				...params
			}
		});
	}
}
