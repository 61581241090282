import { Observable } from 'rxjs';

export abstract class UserRepository {
	abstract getAllPostByUser(username: string, status: string): Observable<any>;
	abstract getProfileDetail(): Observable<any>;
	abstract updateProfile(payload: object): Observable<any>;
	abstract followUser(params: object): Observable<any>;
	abstract unfollowUser(username: string): Observable<any>;
	abstract getFollowingStatus(username: string): Observable<any>;
	abstract getFollowing(username: string, page: number): Observable<any>;
	abstract getFollowers(username: string, page: number): Observable<any>;
	abstract uploadProfilePicture(formData: FormData): Observable<any>;
	abstract getFeaturedUser(): Observable<any>;
	abstract getTopContributor(limit: number): Observable<any>;
}
