<div
	[@fadeAnimation]="{
		value: animationState,
		params: { fadeIn: toastConfig.animation.fadeIn, fadeOut: toastConfig.animation.fadeOut }
	}"
	(@fadeAnimation.done)="onFadeFinished($event)"
	class="flex items-center w-full max-w-xs p-4 mb-4 text-gray-500 bg-white rounded-lg shadow dark:text-gray-400 dark:bg-gray-800">
	<div
		class="inline-flex items-center justify-center flex-shrink-0 w-8 h-8 text-green-500 bg-green-100 rounded-lg dark:bg-green-800 dark:text-green-200">
		<mat-icon [svgIcon]="iconType"></mat-icon>
	</div>
	<div class="ml-3 text-sm font-normal">{{ data.text }}</div>
	<button
		type="button"
		(click)="close()"
		class="ml-auto -mx-1.5 -my-1.5 bg-white text-gray-400 hover:text-gray-900 rounded-lg focus:ring-2 focus:ring-gray-300 p-1.5 hover:bg-gray-100 inline-flex h-8 w-8 dark:text-gray-500 dark:hover:text-white dark:bg-gray-800 dark:hover:bg-gray-700">
		<mat-icon [svgIcon]="'heroicons_outline:x-mark'"></mat-icon>
	</button>
</div>
