import { Observable } from 'rxjs';
import { IRequestRandomList, IRequestGenre } from '@data/interface/store.interface';

export abstract class StoreRepository {
	abstract getCategories(): Observable<any>;
	abstract getGenres(params: IRequestGenre): Observable<any>;
	abstract getRandomList(params: IRequestRandomList): Observable<any>;
	abstract getDetailEbook(id: string): Observable<any>;
	abstract getSearch(params: IRequestRandomList): Observable<any>;
}
