import { Injectable } from '@angular/core';
import { UserRepository } from '../repositories/user.repository';
import { map } from 'rxjs/operators';
import tokenHelper from '../../common/tokenHelper';
import { ProfileModel } from '../../data/models/profile.model';
import { Observable } from 'rxjs';
import { IFollowResponseItem } from '@data/interface/following.interface';
import { IUserTopContributor } from '@data/interface/user.interface';

@Injectable({
	providedIn: 'root'
})
export class UserUsecase {
	constructor(private userRepository: UserRepository) {}

	getAllPostByUser(status: string) {
		const token = tokenHelper.getAccessToken();
		let username = '';
		if (token) {
			username = tokenHelper.decodeToken(token).username;
		}

		return this.userRepository.getAllPostByUser(username || '', status).pipe(
			map((result: any) => {
				return result.data;
			})
		);
	}

	getDetailProfile(): Observable<ProfileModel> {
		return this.userRepository.getProfileDetail().pipe(
			map((result: any) => {
				return result.data;
			})
		);
	}

	updateProfile(
		biography: string,
		fullName: string,
		username: string,
		email: string,
		gender: string,
		dob: string,
		profilePictureUrl: string
	): Observable<boolean> {
		const payload = {
			fullName,
			username,
			email,
			biography,
			gender,
			dateOfBirth: dob,
			photoURL: profilePictureUrl
		};

		return this.userRepository.updateProfile(payload).pipe(
			map((result: any) => {
				return true;
			})
		);
	}

	followUser(params: object): Observable<any> {
		return this.userRepository.followUser(params).pipe(
			map((result: any) => {
				return true;
			})
		);
	}

	unfollowUser(username: string): Observable<any> {
		return this.userRepository.unfollowUser(username).pipe(
			map((result: any) => {
				return true;
			})
		);
	}

	getFollowingStatus(username: string): Observable<boolean> {
		return this.userRepository.getFollowingStatus(username).pipe(
			map((result: any) => {
				if (result.data) {
					return result.data.isFollowing;
				} else {
					return false;
				}
			})
		);
	}

	getFollowing(username: string, page: number): Observable<IFollowResponseItem[]> {
		return this.userRepository.getFollowing(username, page).pipe(
			map((result: any) => {
				if (result.data) {
					return result.data;
				} else {
					return [];
				}
			})
		);
	}

	getFollowers(username: string, page: number): Observable<IFollowResponseItem[]> {
		return this.userRepository.getFollowers(username, page).pipe(
			map((result: any) => {
				if (result.data) {
					return result.data;
				} else {
					return [];
				}
			})
		);
	}

	uploadProfilePicture(formData: FormData): Observable<any> {
		return this.userRepository.uploadProfilePicture(formData).pipe(
			map((result: any) => {
				return result.result;
			})
		);
	}

	getFeaturedUsers(): Observable<any> {
		return this.userRepository.getFeaturedUser().pipe(
			map((result: any) => {
				return result.data;
			})
		);
	}

	getTopContributor(limit: number): Observable<IUserTopContributor[]> {
		return this.userRepository.getTopContributor(limit).pipe(
			map((result: any) => {
				return result.data;
			})
		);
	}
}
