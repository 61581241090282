import { Observable } from 'rxjs';
import { CategoryModel } from '@data/models/category-model';
import { GenreModel } from '@data/models/genre-model';
import { CopyrightModel } from '@data/models/copyright-model';
import { AgeModel } from '@data/models/age-model';
import { GenreSummaryModel } from '@data/models/genre-summary.model';

export abstract class ConfigurationRepository {
	abstract getCategories(): Observable<CategoryModel[]>;
	abstract getGenres(): Observable<GenreModel[]>;
	abstract getGenresNonFiction(): Observable<GenreModel[]>;
	abstract getActiveGenreByCategory(code: string): Observable<GenreSummaryModel[]>;
	abstract getCopyright(): Observable<CopyrightModel[]>;
	abstract getAgeRestriction(): Observable<AgeModel[]>;
	abstract setDraftConfiguration(params: any): Observable<any>;
	abstract getTopGenre(limit: number): Observable<any>;
}
