import { Injectable } from '@angular/core';
import { GoogleAuthProvider } from 'firebase/auth';
import { AngularFireAuth } from '@angular/fire/compat/auth';

@Injectable({
	providedIn: 'root'
})
export class AuthService {
	constructor(public afAuth: AngularFireAuth) {}

	// GoogleAuth() {
	// 	return this.AuthLogin(new GoogleAuthProvider());
	// }

	// AuthLogin(provider: any) {
	// 	return this.afAuth
	// 		.signInWithPopup(provider)
	// 		.then((result: any) => {
	// 			console.log(result.user.multiFactor.user, 'halo');
	// 			console.log(GoogleAuthProvider, 'halo');
	// 			const userGoogle = result.user.multiFactor.user;
	// 			const user: any = {
	// 				accessToken: userGoogle.accessToken,
	// 				displayName: userGoogle.displayName,
	// 				photoURL: userGoogle.photoURL,
	// 				email: userGoogle.email
	// 			};

	// 			return user;
	// 		})
	// 		.catch((error) => {
	// 			console.log(error);
	// 		});
	// }

	setUserSession(user: any) {}
}
