import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { MsUserHttpClient } from '../../common/httpClinet/msUser/msUser.client';

@Injectable({
	providedIn: 'root'
})
export class BookmarkImplRepository {
	constructor(private http: MsUserHttpClient) {}

	getStatus(postId: string): Observable<any> {
		return this.http.get(`/bookmark/${postId}/status`);
	}

	getListBookmarkSelf(): Observable<any> {
		return this.http.get(`/bookmark`);
	}

	addBookmark(payload: object): Observable<any> {
		return this.http.post('/bookmark', payload);
	}

	removeBookmark(payload: object): Observable<any> {
		return this.http.delete('/bookmark', { body: payload });
	}
}
