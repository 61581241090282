import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IBasePost, IRequestPublishPost } from '../interface/post.interface';
import { MsUserHttpClient } from '../../common/httpClinet/msUser/msUser.client';

@Injectable({
	providedIn: 'root'
})
export class SearchImplRepository {
	constructor(private http: MsUserHttpClient) {}

	getSearch(keyword: string): Observable<any> {
		return this.http.get(`/search`, {
			params: {
				keyword: keyword
			}
		});
	}
}
