import { Observable } from 'rxjs';

export abstract class PaymentRepository {
	abstract getListTopUpProduct(): Observable<any>;

	abstract getBalance(userId: string): Observable<any>;

	abstract getOrderDetail(orderId: string): Observable<any>;

	abstract acquireTopUpGateway(payload: object): Observable<any>;

	abstract getHistoryTopup(userId: string, limit: string, page: string): Observable<any>;

	abstract getCoinUsageHistory(userId: string, limit: string, page: string): Observable<any>;

	abstract purchaseChapter(chapterId: string): Observable<any>;

	abstract getPaymentMethod(): Observable<any>;

	abstract purchaseWacaAiPackage(packageId: string): Observable<any>;

	abstract getWriterRoyalty(): Observable<any>;

	abstract purchaseEbook(ebookId: string): Observable<any>;

	abstract getHistoryPaymentEbook(): Observable<any>;

	abstract checkOwnershipEbook(ebookId: string): Observable<any>;
}
