import { TemplateRef } from '@angular/core';
import { ToastNotificationTypeEnum } from './toast-notification.enum';

export interface IToastConfig {
	position: {
		top: number;
		right: number;
	};
	animation: {
		fadeOut: number;
		fadeIn: number;
	};
}

export class ToastData {
	toastType: ToastNotificationTypeEnum;
	text?: string;
	template?: TemplateRef<any>;
	templateContext?: {};

	constructor(
		toastType: ToastNotificationTypeEnum,
		text?: string,
		template?: TemplateRef<any>,
		templateContext?: {}
	) {
		this.toastType = toastType;
		this.text = text;
		this.template = template;
		this.templateContext = templateContext;
	}
}
