import { Injectable } from '@angular/core';
import { MsUserHttpClient } from '../../common/httpClinet/msUser/msUser.client';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class BannerImplRepository {
	constructor(private http: MsUserHttpClient) {}

	getListBanner(): Observable<any> {
		return this.http.get(`/banners`);
	}
}
