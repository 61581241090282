import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { MsUserHttpClient } from '../../common/httpClinet/msUser/msUser.client';

@Injectable({
	providedIn: 'root'
})
export class ProfileImplRepository {
	constructor(private http: MsUserHttpClient) {}

	getDetailProfile(username: string): Observable<any> {
		return this.http.get(`/user/${username}/detail`);
	}

	getAllPostByUser(username: string, status: string): Observable<any> {
		return this.http.get(`/user/${username}/post?status=${status}`);
	}

	getBookmarkByUser(username: string): Observable<any> {
		return this.http.get(`/bookmark`);
	}

	getDetailProfileById(id: string): Observable<any> {
		return this.http.get(`/user/profile/${id}`);
	}
}
