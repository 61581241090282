import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { toastAnimations, ToastAnimationState } from './toast-notification.animation';
import { IToastConfig, ToastData } from './toast-notification.interface';
import { ToastRef } from './toast-ref';
import { TOAST_CONFIG_TOKEN } from './toast-notification.config';
import { AnimationEvent } from '@angular/animations';
import { ToastNotificationTypeEnum } from './toast-notification.enum';

@Component({
	selector: 'app-toast-notification',
	templateUrl: './toast-notification.component.html',
	styleUrls: ['./toast-notification.component.scss'],
	animations: [toastAnimations.fadeToast]
})
export class ToastNotificationComponent implements OnInit, OnDestroy {
	animationState: ToastAnimationState = 'default';
	iconType = 'heroicons_outline:check';

	private intervalId: NodeJS.Timer | null = null;

	constructor(
		readonly data: ToastData,
		readonly ref: ToastRef,
		@Inject(TOAST_CONFIG_TOKEN)
		public toastConfig: IToastConfig
	) {
		switch (data.toastType) {
			case ToastNotificationTypeEnum.SUCCESS:
				this.iconType = 'heroicons_outline:check';
				break;
			case ToastNotificationTypeEnum.INFO:
				this.iconType = 'heroicons_outline:information-circle';
				break;
			case ToastNotificationTypeEnum.WARNING:
				this.iconType = 'heroicons_outline:exclamation-triangle';
				break;
			case ToastNotificationTypeEnum.ERROR:
				this.iconType = 'heroicons_outline:x-mark';
				break;
		}
	}

	ngOnInit(): void {
		this.intervalId = setTimeout(() => (this.animationState = 'closing'), 5000);
	}

	ngOnDestroy(): void {
		if (this.intervalId) {
			clearTimeout(this.intervalId);
		}
	}

	close() {
		this.ref.close();
	}

	onFadeFinished(event: AnimationEvent) {
		const { toState } = event;
		const isFadeOut = toState === 'closing';
		const itFinished = this.animationState === 'closing';

		if (isFadeOut && itFinished) {
			this.close();
		}
	}
}
