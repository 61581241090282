import { Observable } from 'rxjs';
import { MsUserHttpClient } from '../../common/httpClinet/msUser/msUser.client';
import { Injectable } from '@angular/core';
import { MsFileHttpClient } from '../../common/httpClinet/msFile/msFile.client';

@Injectable({
	providedIn: 'root'
})
export class UserAssetsImplRepository {
	constructor(private http: MsUserHttpClient, private fileHttpClient: MsFileHttpClient) {}

	getAssetByUser(userId: string): Observable<any> {
		return this.http.get(`/user/${userId}/assets`);
	}

	deleteAssetByUrl(fileUrl: string): Observable<any> {
		return this.fileHttpClient.delete(`/public/delete/cover`, {
			body: {
				url: fileUrl
			}
		});
	}
}
