import { ModuleWithProviders, NgModule } from '@angular/core';
import { DialogConfirmWriterComponent } from './dialog-confirm-writer.component';
import { RouterLink } from '@angular/router';

@NgModule({
	declarations: [DialogConfirmWriterComponent],
	imports: [RouterLink]
})
export class DialogConfirmationWriterModule {
	public static forRoot(): ModuleWithProviders<any> {
		return {
			ngModule: DialogConfirmationWriterModule
		};
	}
}
