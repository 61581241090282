import { Injectable } from '@angular/core';
import { IAppConfiguration } from '@data/interface/configuration.interface';
import { Subject } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class AppConfigurationService {
	private appConfigurationSubject = new Subject<IAppConfiguration>();
	appConfiguration$ = this.appConfigurationSubject.asObservable();

	constructor() {}

	emitEvent(appConfiguration: IAppConfiguration) {
		this.appConfigurationSubject.next(appConfiguration);
	}
}
