import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { MsUserHttpClient } from '../../common/httpClinet/msUser/msUser.client';

@Injectable({
	providedIn: 'root'
})
export class AuthenticationImplRepository {
	constructor(private http: MsUserHttpClient) {}

	socialLogin(params: any): Observable<any> {
		return this.http.post('/users/social-login', params);
	}

	loginWithEmailAndPassword(payload: object): Observable<any> {
		return this.http.post('/auth/login', payload);
	}

	refreshAccessToken(payload: object): Observable<any> {
		return this.http.post('/auth/refresh', payload);
	}

	logout(): Observable<any> {
		return this.http.post('/auth/logout', {});
	}
}
