export const REGEX = {
	USERNAME: RegExp('^[A-Za-z0-9_.]{6,15}$'),
	PERSON_NAME_REGEX: new RegExp(`^[a-zA-Z0-9-,.' ]+$`),
	PHONE_NUMBER_REGEX: new RegExp('^\\+?[0-9]+$')
};

export const HTTP_CONFIG = {
	API_TIMEOUT: 30000,
	DEFAULT_RETRY: 1
};

export const READING_TIME_CONFIG = {
	MIN: 3000,
	MAX: 5000
};

export const REMOTE_CONFIG_KEYS = {
	SHOW_BOOK_STORE: 'platform_showBookStore_20240114'
};

export const WACACOIN = {
	WACA_COIN_RATE_IN_RUPIAH: 600
};

export const IS_SHOW_DOWNLOAD_APPS_KEY = 'is_show_download_apps';

export const ICON_TYPE = {
	ASSET: 'ASSET',
	LINK: 'LINK'
};
