import { Injectable } from '@angular/core';
import { MsUserHttpClient } from '../../common/httpClinet/msUser/msUser.client';
import { HttpHeaders } from '@angular/common/http';

@Injectable({
	providedIn: 'root'
})
export class WacaAiImplRepository {
	constructor(private http: MsUserHttpClient) {}

	getAllPrompts() {
		return this.http.get('/waca-ai/prompts');
	}

	getAllPackages() {
		return this.http.get('/waca-ai/packages');
	}

	getRemainingQuotaAndDuration() {
		return this.http.get('/waca-ai/packages/quota');
	}

	generateWordsFromPrompt(payload: { promptId: string; sentence: string }) {
		return this.http.post(`/waca-ai/generate`, payload, {
			headers: new HttpHeaders().set('timeout', '120000')
		});
	}
}
