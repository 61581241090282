import { Observable } from 'rxjs';
import { IRequestGetEvents, IRequestGetEventsByPublisher } from '@data/interface/events.interface';

export abstract class EventsRepository {
	abstract getAllEvents(params: IRequestGetEvents): Observable<any>;
	abstract getEventsByUrl(url: string): Observable<any>;
	abstract getAllRegisteredEvents(): Observable<any>;
	abstract getAllProfileEvents(username: string): Observable<any>;
	abstract registerEvent(params: object, id: string): Observable<any>;
	abstract checkStatusEvent(id: string): Observable<any>;
	abstract checkEventSubmissionStatus(id: string): Observable<any>;
	abstract getDetailRegistration(id: string): Observable<any>;
	abstract submitEvent(id: string, postId: string): Observable<any>;
	abstract submitPaymentEvent(payload: object): Observable<any>;
	abstract getListEventsByPublisher(
		params: IRequestGetEventsByPublisher,
		publisherId: string
	): Observable<any>;
}
