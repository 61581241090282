import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
// import { MsUserHttpClient } from '../../common/httpClinet/msUser/msUser.client';
import { MsStoreHttpClient } from '../../common/httpClinet/msStore/msStore.client';
import { CollectionCategoryEnum } from '@data/enum/ebook.bookmark.enum';
@Injectable({
	providedIn: 'root'
})
export class EbookBookmarkImplRepository {
	constructor(private http: MsStoreHttpClient) {}

	getEbookStatus(ebookId: string): Observable<any> {
		return this.http.get(`/bookmark/${ebookId}/check`);
	}

	getListEbookBookmarkSelf(): Observable<any> {
		return this.http.get(`/bookmark`);
	}

	addEbookBookmark(payload: object): Observable<any> {
		return this.http.post('/ebook/bookmark', payload);
	}

	removeEbookBookmark(payload: object): Observable<any> {
		return this.http.delete('/ebook/bookmark', { body: payload });
	}

	updateEbookBookmark(action: string, ebookId: string): Observable<any> {
		return this.http.post(`/bookmark/${ebookId}?action=${action}`, {});
	}

	getCollection(category: CollectionCategoryEnum): Observable<any> {
		return this.http.get(`/collection`, {
			params: {
				category
			}
		});
	}
}
