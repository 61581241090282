import { Observable } from 'rxjs';
import { MonetisakuStatusEnum } from '@data/enum/monetisaku.enum';

export abstract class MonetisakuRepository {
	abstract applyMonetisaku(postId: string): Observable<any>;

	abstract getStatusMonetisaku(): Observable<any>;

	abstract getDetailLatestPendingMonetisaku(): Observable<any>;

	abstract getListMonetisakuByStatus(status: MonetisakuStatusEnum): Observable<any>;

	abstract getDetailEnrollmentById(id: string): Observable<any>;

	abstract getMonetisakuBalance(): Observable<any>;

	abstract getRateFee(): Observable<any>;

	abstract getCreatorEarningList(): Observable<any>;

	abstract submitWithdrawal(payload: object): Observable<any>;
}
