import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { MsUserHttpClient } from '../../common/httpClinet/msUser/msUser.client';

@Injectable({
	providedIn: 'root'
})
export class ChapterImplRepository {
	constructor(private http: MsUserHttpClient) {}

	createChapter(params: any): Observable<any> {
		return this.http.post(`/post/${params.id_post}/chapter`, {
			title: params.title,
			content: params.content,
			postStatus: params.postStatus
		});
	}

	getListPublishedChapterByPostId(postId: string): Observable<any> {
		return this.http.get(`/post/${postId}/chapter`);
	}

	getAllChapterByPostId(postId: string): Observable<any> {
		return this.http.get(`/post/${postId}/chapter/all`);
	}

	deleteChapter(params: any): Observable<any> {
		return this.http.delete(`/post/${params.id_post}/chapter/${params.id_chapter}`);
	}

	getOneChapter(params: any): Observable<any> {
		return this.http.get(`/post/${params.id_post}/chapter/${params.id_chapter}`);
	}

	getDetailChapter(chapterId: string): Observable<any> {
		return this.http.get(`/chapter/${chapterId}`);
	}

	getDetailChapterForAuthor(chapterId: string): Observable<any> {
		return this.http.get(`/chapter/author/${chapterId}`);
	}

	getFirstChapter(postId: string): Observable<any> {
		return this.http.get(`/post/${postId}/first-chapter`);
	}

	editChapter(params: any): Observable<any> {
		return this.http.patch(`/post/${params.id_post}/chapter/${params.id_chapter}`, {
			title: params.title,
			content: params.content,
			postStatus: params.postStatus
		});
	}

	savePendingChanges(postId: string, chapterId: string, payload: object): Observable<any> {
		return this.http.patch(`/post/${postId}/chapter/${chapterId}/pending`, payload);
	}

	getLikeStatus(chapterId: string): Observable<any> {
		return this.http.get(`/chapter/like/${chapterId}/status`);
	}

	addLike(payload: object): Observable<any> {
		return this.http.post(`/chapter/like`, payload);
	}

	removeLike(payload: object): Observable<any> {
		return this.http.delete(`/chapter/unlike`, {
			body: payload
		});
	}
}
