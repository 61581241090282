import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ToastNotificationComponent } from './toast-notification.component';
import { MatIconModule } from '@angular/material/icon';
import { defaultToastConfig, TOAST_CONFIG_TOKEN } from './toast-notification.config';

@NgModule({
	declarations: [ToastNotificationComponent],
	imports: [CommonModule, MatIconModule]
})
export class ToastNotificationModule {
	public static forRoot(config = defaultToastConfig): ModuleWithProviders<any> {
		return {
			ngModule: ToastNotificationModule,
			providers: [
				{
					provide: TOAST_CONFIG_TOKEN,
					useValue: { ...defaultToastConfig, ...config }
				}
			]
		};
	}
}
