import { Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
	selector: 'app-button',
	templateUrl: './button.component.html',
	styleUrls: ['./button.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class ButtonComponent {
	@Input() isLoading = false;
	@Input() text: any;
	@Input() isOutline = false;
	@Input() isDisabled = false;
	@Input() customClass = '';
	@Input() icon = '';

	constructor() {}
}
