import { Injectable } from '@angular/core';
import { PaymentRepository } from '@domain/repositories/payment.repository';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
	IBalance,
	ICoinUsageHistoryItem,
	IGroupedPaymentChannelMethod,
	IGroupedPaymentMethod,
	IOrderDetail,
	IPaymentChannelMethod,
	IPaymentInquiryRequest,
	IPaymentMethod,
	ITopUpProduct
} from '@data/interface/payment.interface';
import tokenHelper from '../../common/tokenHelper';
import { AuthenticationUsecase } from '@domain/usecase/authentication.usecase';
import { ProfileModel } from '@data/models/profile.model';
import { IRoyalty } from '@data/interface/monetisaku.interface';

@Injectable({
	providedIn: 'root'
})
export class PaymentUsecase {
	constructor(
		private paymentRepository: PaymentRepository,
		private authenticationUsecase: AuthenticationUsecase
	) { }

	getWriterRoyalty(): Observable<IRoyalty> {
		return this.paymentRepository.getWriterRoyalty().pipe(
			map((response: any) => {
				return response.data;
			})
		);
	}

	getListTopUpProduct(): Observable<ITopUpProduct[]> {
		return this.paymentRepository.getListTopUpProduct().pipe(
			map((response: any) => {
				return response.data;
			})
		);
	}

	getBalance(): Observable<IBalance> {
		const accessToken = tokenHelper.getAccessToken();
		const decodedToken = tokenHelper.decodeToken(accessToken || '');
		return this.paymentRepository.getBalance(decodedToken.userId).pipe(
			map((response: any) => {
				return response.data;
			})
		);
	}

	getOrderDetail(orderId: string): Observable<IOrderDetail> {
		return this.paymentRepository.getOrderDetail(orderId).pipe(
			map((response: any) => {
				return response.data;
			})
		);
	}

	acquireTopUpGateway(
		product: ITopUpProduct,
		paymentMethodId: number,
		user: ProfileModel
	): Observable<object> {
		const token = tokenHelper.getAccessToken();
		const decodedToken = tokenHelper.decodeToken(token || '');
		const fullName = user.fullName.split(' ');
		const firstName = fullName ? fullName[0] : '';
		const lastName = fullName && fullName[1] ? fullName[1] : '-';

		const payload = {
			user_id: decodedToken.userId,
			price: product.price,
			topup_price_id: product.id,
			email: decodedToken.email,
			first_name: firstName,
			last_name: lastName,
			payment_id: paymentMethodId
		};
		return this.paymentRepository.acquireTopUpGateway(payload).pipe(
			map((response: any) => {
				return response.data;
			})
		);
	}

	inquiryPaymentChannel(
		payload: IPaymentInquiryRequest
	): Observable<object> {
		return this.paymentRepository.inquiryPaymentChannel(payload).pipe(
			map((response: any) => {
				return response.data;
			})
		);
	}

	getHistoryTopup(): Observable<object> {
		const accessToken = tokenHelper.getAccessToken();
		const decodedToken = tokenHelper.decodeToken(accessToken || '');
		return this.paymentRepository.getHistoryTopup(decodedToken.userId, '100', '1').pipe(
			map((response: any) => {
				return response.data;
			})
		);
	}

	getUsageHistory(page: number): Observable<ICoinUsageHistoryItem[]> {
		const limit = 25;

		const accessToken = tokenHelper.getAccessToken();
		const decodedToken = tokenHelper.decodeToken(accessToken || '');
		return this.paymentRepository
			.getCoinUsageHistory(decodedToken.userId, limit.toString(), page.toString())
			.pipe(
				map((response: any) => {
					return response.data;
				})
			);
	}

	purchaseChapter(chapterId: string): Observable<object> {
		return this.paymentRepository.purchaseChapter(chapterId).pipe(
			map((response: any) => {
				return response.data;
			})
		);
	}

	getPaymentMethod(): Observable<IGroupedPaymentMethod> {
		return this.paymentRepository.getPaymentMethod().pipe(
			map((response: any) => {
				const data: IPaymentMethod[] = response.data;
				return this.groupByGroupName(data);
			})
		);
	}

	groupByGroupName(data: IPaymentMethod[]): IGroupedPaymentMethod {
		return data.reduce((groups, item) => {
			const groupName = item.group_name;
			if (!groups[groupName]) {
				groups[groupName] = [];
			}
			groups[groupName].push(item);
			return groups;
		}, {} as IGroupedPaymentMethod);
	}

	purchaseWacaAiPackage(packageId: string): Observable<any> {
		return this.paymentRepository.purchaseWacaAiPackage(packageId).pipe(
			map((response: any) => {
				return response.data;
			})
		);
	}

	purchaseEbook(ebookId: string): Observable<object> {
		return this.paymentRepository.purchaseEbook(ebookId).pipe(
			map((response: any) => {
				return response.data;
			})
		);
	}

	getHistoryPaymentEbook(): Observable<any> {
		return this.paymentRepository.getHistoryPaymentEbook().pipe(
			map((response: any) => {
				return response.data;
			})
		);
	}

	checkOwnershipEbook(ebookId: string): Observable<any> {
		return this.paymentRepository.checkOwnershipEbook(ebookId).pipe(
			map((response: any) => {
				return response.data;
			})
		);
	}

	getListPaymentChannels(): Observable<any> {
		return this.paymentRepository.getListPaymentChannels().pipe(
			map((response: any) => {
				const data: IPaymentChannelMethod[] = response.data;
				// const data: IPaymentMethod[] = response.data;
				// return this.groupByGroupName(data);

				return this.groupByCategoryName(data)
			})
		);
	}

	groupByCategoryName(data: IPaymentChannelMethod[]): { category: string, items: IPaymentChannelMethod[] }[] {
		const groups: { category: string, items: IPaymentChannelMethod[] }[] = [];

		data.forEach((item) => {
			const categoryName = item.pay_category_name;
			let group = groups.find((group) => group.category === categoryName);
			if (!group) {
				group = { category: categoryName, items: [] };
				groups.push(group);
			}
			group.items.push(item);
		});

		return groups; // Returns an array of objects to maintain the order


	}
}
