<div id="popup-modal" class="w-full rounded-lg">
	<div class="w-full h-full max-w-md md:h-auto">
		<div class="relative bg-white rounded-lg shadow dark:bg-gray-700">
			<button
				(click)="cancel()"
				class="absolute hidden top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white"
				data-modal-hide="popup-modal">
				<mat-icon [svgIcon]="'heroicons_outline:x-mark'"></mat-icon>
			</button>
			<div class="p-6 text-center">
				<h3 class="mb-5 text-base font-normal text-gray-500 dark:text-gray-400">
					{{ data.message }}
				</h3>
				<button
					(click)="cancel()"
					class="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600 mr-2">
					Tidak
				</button>
				<button
					(click)="confirm()"
					class="text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center">
					{{ data.confirmText || 'Ya' }}
				</button>
			</div>
		</div>
	</div>
</div>
