import { Component, Inject } from '@angular/core';
import { GoogleAuthProvider } from 'firebase/auth';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AuthenticationUsecase } from '@domain/usecase/authentication.usecase';
import { Router } from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import mixpanel from 'mixpanel-browser';

@Component({
	selector: 'app-login-dialog',
	templateUrl: './login-dialog.component.html',
	styleUrls: ['./login-dialog.component.scss']
})
export class LoginDialogComponent {
	users: any;

	isLoading = false;
	errorMessage = '';

	form: FormGroup;
	formObject = {
		fcEmail: new FormControl('', [
			Validators.required,
			Validators.email,
			Validators.maxLength(100)
		]),
		fcPassword: new FormControl('', Validators.required)
	};

	constructor(
		public dialogRef: MatDialogRef<LoginDialogComponent>,
		public afAuth: AngularFireAuth,
		private authentication: AuthenticationUsecase,
		private router: Router,
		@Inject(MAT_DIALOG_DATA)
		public data: {
			redirectToHomepageOnCancel: boolean;
		}
	) {
		dialogRef.disableClose = true;
		this.form = new FormGroup(this.formObject);
	}

	onClose() {
		const currentPath = this.router.url;
		if (currentPath === '/') {
			this.dialogRef.close();
			return;
		}

		if (this.data.redirectToHomepageOnCancel) {
			this.router.navigate(['/']).then(() => {
				this.dialogRef.close();
			});
		} else {
			this.dialogRef.close();
		}
	}

	readTermAndCondition() {
		this.router.navigate(['/term-and-conditions']).then(() => {
			this.dialogRef.close();
		});
	}

	doLoginWithGoogle() {
		this.isLoading = true;
		this.afAuth.signInWithPopup(new GoogleAuthProvider()).then((result: any) => {
			const userGoogle = result.user.multiFactor.user;

			const user: any = {
				socialId: userGoogle.uid,
				idToken: userGoogle.accessToken,
				fullName: userGoogle.displayName,
				photoUrl: userGoogle.photoURL,
				email: userGoogle.email
			};

			this.authentication.socialLogin(user).subscribe({
				next: (res) => {
					this.users = user;
					mixpanel.identify(user.email);
					this.isLoading = false;
					this.dialogRef.close(user);
				},
				error: (err) => {
					this.isLoading = false;
					this.errorMessage = err;
				}
			});
		});
	}

	doLoginWithEmailAndPassword() {
		const email = this.formObject.fcEmail.value ?? '';
		const pass = this.formObject.fcPassword.value ?? '';

		if (!email || !pass) {
			return;
		}

		this.isLoading = true;
		this.errorMessage = '';
		this.authentication.loginWIthEmailAndPassword(email, pass).subscribe({
			next: (res) => {
				this.isLoading = false;
				this.dialogRef.close(res);
			},
			error: (err) => {
				this.isLoading = false;
				this.errorMessage = err;
			}
		});
	}
}
