import { Injectable } from '@angular/core';
import { AuthenticationUsecase } from '@domain/usecase/authentication.usecase';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import tokenHelper from '../tokenHelper';
import { MatDialog } from '@angular/material/dialog';

@Injectable({
	providedIn: 'root'
})
export class AuthGuard implements CanActivate {
	constructor(
		public dialog: MatDialog,
		private router: Router,
		private authentication: AuthenticationUsecase
	) {}

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
		if (this.isLoggedIn()) {
			return true;
		}
		// navigate to login page as user is not authenticated
		// this.router.navigate(['/']);
		return false;
	}

	public isLoggedIn(): boolean {
		const tokenJwt = tokenHelper.getAccessToken();
		if (!tokenJwt) {
			this.authentication.showLoginDialog();
			return false;
		}
		const decodedToken = tokenHelper.decodeToken(tokenJwt);

		if (decodedToken) {
			return true;
		} else {
			this.authentication.showLoginDialog();
			return false;
		}
	}
}
