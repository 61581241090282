import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserWacaKoinComponent } from './user-waca-koin.component';
import { DirectivesModule } from '../../../common/directive/count-up/directives.module';

@NgModule({
	declarations: [UserWacaKoinComponent],
	exports: [UserWacaKoinComponent],
	imports: [CommonModule, DirectivesModule]
})
export class UserWacaKoinModule {}
