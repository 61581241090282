import { Component, HostListener, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
	selector: 'app-dialog-confirmation',
	templateUrl: './dialog-confirmation.component.html',
	styleUrls: ['./dialog-confirmation.component.scss']
})
export class DialogConfirmationComponent {
	constructor(
		@Inject(MAT_DIALOG_DATA)
		public data: {
			message: string;
			confirmText: string;
		},
		private mdDialogRef: MatDialogRef<DialogConfirmationComponent>
	) {}

	public cancel() {
		this.close(false);
	}

	public close(value: any) {
		this.mdDialogRef.close(value);
	}

	public confirm() {
		this.close(true);
	}

	@HostListener('keydown.esc')
	public onEsc() {
		this.close(false);
	}
}
