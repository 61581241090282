import { IDecodedToken } from '../data/models/authentication.model';
import * as CryptoJS from 'crypto-js';
import { environment, environment as devEnvironment } from '../../environments/environment';
import { environment as prodEnvironment } from '../../environments/environment.prod';

const userKey = !environment.production ? devEnvironment.lsUserKey : prodEnvironment.lsUserKey;

const accessTokenKey = !environment.production
	? devEnvironment.lsAccessTokenKey
	: prodEnvironment.lsAccessTokenKey;

const refreshTokenKey = !environment.production
	? devEnvironment.lsRefreshTokenKey
	: prodEnvironment.lsRefreshTokenKey;

const decodeToken = (token: string): IDecodedToken => {
	const base64Url = token.split('.')[1];
	const base64 = base64Url.replace('-', '+').replace('_', '/');
	return JSON.parse(atob(base64));
};

const getAccessToken = (): string | null => {
	const token: any = localStorage.getItem('_at');
	if (token) {
		const bytes = CryptoJS.AES.decrypt(token, accessTokenKey);

		try {
			return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
		} catch (Exception) {
			return null;
		}
	}

	return null;
};

const tokenHelper = {
	decodeToken,
	getAccessToken
};
export default tokenHelper;
