import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { MsStoreHttpClient } from '../../common/httpClinet/msStore/msStore.client';

@Injectable({
	providedIn: 'root'
})
export class PublisherImplRepository {
	constructor(private http: MsStoreHttpClient) {}

	getSales(id: string): Observable<any> {
		return this.http.get(`/ebook/reader/publisher/${id}/sales`);
	}
}
