import { MsUserHttpClient } from '../../common/httpClinet/msUser/msUser.client';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { MonetisakuStatusEnum } from '@data/enum/monetisaku.enum';

@Injectable({
	providedIn: 'root'
})
export class MonetisakuImplRepository {
	constructor(private http: MsUserHttpClient) {}

	applyMonetisaku(postId: string): Observable<any> {
		return this.http.post(`/monetisaku/apply`, {
			postId
		});
	}

	getStatusMonetisaku(): Observable<any> {
		return this.http.get(`/monetisaku/status`);
	}

	getDetailLatestPendingMonetisaku(): Observable<any> {
		return this.http.get(`/monetisaku/on-progress`);
	}

	getListMonetisakuByStatus(status: MonetisakuStatusEnum): Observable<any> {
		return this.http.get(`/monetisaku/list/${status}`);
	}

	getDetailEnrollmentById(id: string): Observable<any> {
		return this.http.get(`/monetisaku/enrollment/${id}`);
	}

	getMonetisakuBalance(): Observable<any> {
		return this.http.get(`/monetisaku/dashboard/balance`);
	}

	getRateFee(): Observable<any> {
		return this.http.get(`/monetisaku/rate-fee`);
	}

	getCreatorEarningList(): Observable<any> {
		return this.http.get(`/monetisaku/unlocked-chapter/summary`);
	}

	submitWithdrawal(payload: object): Observable<any> {
		return this.http.post(`/monetisaku/withdrawal`, payload);
	}
}
