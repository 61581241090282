import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IRequestGetEvents, IRequestGetEventsByPublisher } from '../interface/events.interface';
import { MsUserHttpClient } from '../../common/httpClinet/msUser/msUser.client';

@Injectable({
	providedIn: 'root'
})
export class EventsImplRepository {
	constructor(private http: MsUserHttpClient) {}

	getAllEvents(params: IRequestGetEvents): Observable<any> {
		return this.http.get(`/events`, {
			params: {
				page: params.page,
				limit: params.limit
			}
		});
	}

	getEventsByUrl(url: string): Observable<any> {
		return this.http.get(`/event/` + url);
	}

	getAllRegisteredEvents(): Observable<any> {
		return this.http.get(`/event/me`);
	}

	getAllProfileEvents(username: string): Observable<any> {
		return this.http.get(`/event/registered/${username}`);
	}

	registerEvent(params: object, id: string): Observable<any> {
		return this.http.post(`/event/${id}/register`, params);
	}

	checkStatusEvent(id: string): Observable<any> {
		return this.http.get(`/event/${id}/status`);
	}

	checkEventSubmissionStatus(id: string): Observable<any> {
		return this.http.get(`/event/${id}/submit/status`);
	}

	getDetailRegistration(id: string): Observable<any> {
		return this.http.get(`/event/${id}/registration/detail`);
	}

	submitEvent(id: string, postId: string): Observable<any> {
		return this.http.post(`/event/${id}/submit`, {
			postId
		});
	}

	submitPaymentEvent(payload: object): Observable<any> {
		const url = `/payment/event`;
		return this.http.post(url, payload);
	}

	getListEventsByPublisher(
		params: IRequestGetEventsByPublisher,
		publisherId: string
	): Observable<any> {
		return this.http.get(`/publisher/${publisherId}/events`, {
			params: {
				...params
			}
		});
	}
}
