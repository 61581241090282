import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthenticationRepository } from '../repositories/authentication.repository';
import { map } from 'rxjs/operators';
import * as CryptoJS from 'crypto-js';
import tokenHelper from '../../common/tokenHelper';
import { environment, environment as devEnvironment } from '../../../environments/environment';
import { environment as prodEnvironment } from '../../../environments/environment.prod';
import { UserRepository } from '../repositories/user.repository';
import { ProfileModel } from '@data/models/profile.model';
import { LoginDialogComponent } from '@components/login-dialog/login-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { UserService } from '../../common/services/user.service';

@Injectable({
	providedIn: 'root'
})
export class AuthenticationUsecase {
	user: ProfileModel = new ProfileModel();

	userKey = !environment.production ? devEnvironment.lsUserKey : prodEnvironment.lsUserKey;

	accessTokenKey = !environment.production
		? devEnvironment.lsAccessTokenKey
		: prodEnvironment.lsAccessTokenKey;

	refreshTokenKey = !environment.production
		? devEnvironment.lsRefreshTokenKey
		: prodEnvironment.lsRefreshTokenKey;

	constructor(
		private authentication: AuthenticationRepository,
		private userRepository: UserRepository,
		public dialog: MatDialog,
		private userService: UserService
	) {}

	socialLogin(params: any): Observable<any> {
		return this.authentication.socialLogin(params).pipe(
			map((result: any) => {
				if (result.data) {
					this.setToken(result.data.token);
					this.setRefreshToken(result.data.refreshToken);

					this.userRepository
						.getProfileDetail()
						.pipe(
							map((res) => {
								return res.data as ProfileModel;
							})
						)
						.subscribe((profile) => {
							this.setUser(profile);
							this.userService.emitEvent(profile);
							this.user = profile;
						});

					return result.data;
				} else {
					return {};
				}
			})
		);
	}

	loginWIthEmailAndPassword(email: string, password: string): Observable<any> {
		const payload = {
			email,
			password
		};
		return this.authentication.loginWithEmailAndPassword(payload).pipe(
			map((result: any) => {
				if (result.data) {
					this.setToken(result.data.token);
					this.setRefreshToken(result.data.refreshToken);

					this.userRepository
						.getProfileDetail()
						.pipe(
							map((res) => {
								return res.data as ProfileModel;
							})
						)
						.subscribe((profile) => {
							this.setUser(profile);
							this.user = profile;
						});

					return result.data;
				} else {
					return {};
				}
			})
		);
	}

	refreshAccessToken(payload: object): Observable<object> {
		return this.authentication.refreshAccessToken(payload);
	}

	logout(): void {
		this.authentication.logout().subscribe((res) => {});
	}

	isUserLoggedIn(): boolean {
		const tokenJwt = this.getToken();
		if (!tokenJwt) {
			return false;
		}

		const dataUser = tokenHelper.decodeToken(tokenJwt);
		return !!(tokenJwt && dataUser);
	}

	setUser(user: ProfileModel) {
		const encryptToken = CryptoJS.AES.encrypt(JSON.stringify(user), this.userKey).toString();

		localStorage.setItem('_u', encryptToken);
	}

	getUser(): ProfileModel | null {
		const token: any = localStorage.getItem('_u');
		if (token) {
			const bytes = CryptoJS.AES.decrypt(token, this.userKey);

			try {
				return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
			} catch (Exception) {
				return null;
			}
		}

		return null;
	}

	setToken(token: string) {
		if (token) {
			const encryptedToken = CryptoJS.AES.encrypt(
				JSON.stringify(token),
				this.accessTokenKey
			).toString();

			localStorage.setItem('_at', encryptedToken);
		}
	}

	setRefreshToken(refreshToken: string) {
		if (refreshToken) {
			const encryptedToken = CryptoJS.AES.encrypt(
				JSON.stringify(refreshToken),
				this.refreshTokenKey
			).toString();

			localStorage.setItem('_rt', encryptedToken);
		}
	}

	getToken(): string | null {
		const token: any = localStorage.getItem('_at');
		if (token) {
			const bytes = CryptoJS.AES.decrypt(token, this.accessTokenKey);

			try {
				return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
			} catch (Exception) {
				return null;
			}
		}

		return null;
	}

	getRefreshToken(): string | null {
		const token: any = localStorage.getItem('_rt');
		if (token) {
			const bytes = CryptoJS.AES.decrypt(token, this.refreshTokenKey);

			try {
				return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
			} catch (Exception) {
				return null;
			}
		}

		return null;
	}

	b64DecodeUnicode(str: any) {
		return decodeURIComponent(
			Array.prototype.map
				.call(atob(str), (c) => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2))
				.join('')
		);
	}

	parseJwt(token: any) {
		if (token) {
			return JSON.parse(
				this.b64DecodeUnicode(token.split('.')[1].replace('-', '+').replace('_', '/'))
			);
		}

		return false;
	}

	// return true if now greater than expired time
	isRefreshTokenExpired(): boolean {
		const refreshToken = this.getRefreshToken();
		if (refreshToken) {
			const decodedToken = tokenHelper.decodeToken(refreshToken);
			const expiredRefreshToken = decodedToken.exp * 1000;
			const now = Date.now();
			return now >= expiredRefreshToken;
		}
		return true;
	}

	showLoginDialog(redirectToHomepageOnCancel: boolean = true) {
		this.dialog.open(LoginDialogComponent, {
			width: '400px',
			panelClass: 'custom-dialog-container',
			data: {
				redirectToHomepageOnCancel
			}
		});
		this.dialog.afterAllClosed.subscribe((res) => {
			if (res !== undefined) {
				window.location.reload();
			}
		});
	}
}
