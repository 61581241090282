import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { MsNotificationHttpClient } from '../../common/httpClinet/msNotification/msNotification.client';
import { IUpdateNotification } from '@data/interface/notification.interface';
@Injectable({
	providedIn: 'root'
})
export class NotificationImplRepository {
	constructor(private http: MsNotificationHttpClient) {}

	getNotificationHistory(): Observable<any> {
		return this.http.get('/notification-history');
	}

	updateNotification(params: IUpdateNotification, id: string): Observable<any> {
		return this.http.post(`/notification-history/${id}`, params);
	}
}
