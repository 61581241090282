import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ImageCropperDialogComponent } from './image-cropper-dialog.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { MatDialogModule } from '@angular/material/dialog';
import { ButtonModule } from '@components/button/button.module';

@NgModule({
	declarations: [ImageCropperDialogComponent],
	imports: [CommonModule, ImageCropperModule, MatDialogModule, ButtonModule]
})
export class ImageCropperDialogModule {
	public static forRoot(): ModuleWithProviders<any> {
		return {
			ngModule: ImageCropperDialogModule
		};
	}
}
