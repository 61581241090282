import { Inject, Injectable, Injector } from '@angular/core';
import { ToastRef } from './toast-ref';
import { Overlay } from '@angular/cdk/overlay';
import { TOAST_CONFIG_TOKEN } from './toast-notification.config';
import { IToastConfig, ToastData } from './toast-notification.interface';
import { ComponentPortal, PortalInjector } from '@angular/cdk/portal';
import { ToastNotificationComponent } from './toast-notification.component';

@Injectable({
	providedIn: 'root'
})
export class ToastNotificationService {
	private lastToast: ToastRef | null = null;

	constructor(
		private overlay: Overlay,
		private parentInjector: Injector,
		@Inject(TOAST_CONFIG_TOKEN) private toastConfig: IToastConfig
	) {}

	show(data: ToastData) {
		const positionStrategy = this.getPositionStrategy();
		const overlayRef = this.overlay.create({ positionStrategy });

		const toastRef = new ToastRef(overlayRef);
		this.lastToast = toastRef;

		const injector = this.getInjector(data, toastRef, this.parentInjector);
		const toastPortal = new ComponentPortal(ToastNotificationComponent, null, injector);

		overlayRef.attach(toastPortal);

		return toastRef;
	}

	getPositionStrategy() {
		return this.overlay
			.position()
			.global()
			.top(this.getPosition())
			.right(this.toastConfig.position.right + 'px');
	}

	getPosition() {
		if (this.lastToast) {
			const lastToastIsVisible = this.lastToast && this.lastToast.isVisible();
			const position = lastToastIsVisible
				? this.lastToast.getPosition().bottom
				: this.toastConfig.position.top;

			return position + 'px';
		} else {
			return this.toastConfig.position.top + 'px';
		}
	}

	getInjector(data: ToastData, toastRef: ToastRef, parentInjector: Injector) {
		const tokens = new WeakMap();

		tokens.set(ToastData, data);
		tokens.set(ToastRef, toastRef);

		return new PortalInjector(parentInjector, tokens);
	}
}
